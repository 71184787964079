@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Urbanist";
  scroll-behavior: smooth;
}

.delivered {
  @apply flex items-center bg-[#2B8C34] rounded-[4px] px-4 py-1 mt-2;
}

.deliveredText {
  @apply text-white font-medium text-sm;
}

.processing {
  @apply flex items-center bg-[#D5A407] rounded-[4px] px-4 py-1 mt-2;
}

.processingText {
  @apply text-white font-medium text-sm;
}

.intransit {
  @apply flex items-center bg-[#0066AF] rounded-[4px] px-4 py-1 mt-2;
}

.intransitText {
  @apply text-white font-medium text-sm;
}

.cancelled {
  @apply flex items-center bg-[#B92043] rounded-[4px] px-4 py-1 mt-2;
}

.cancelledText {
  @apply text-white font-medium text-sm;
}

/* react select custom styles */
.select_style .select_control__control {
  border-radius: 8px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #96a397;
  color: #333333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: 48px;
  width: 100%;
}

.select_style .select_control__control:hover {
  border: 1px solid #abb6ac !important;
  box-shadow: none !important;
}

.select_control__menu .select_control__menu-list .select_control__option {
  cursor: pointer;
  font-size: 14px;
  color: #333333;
}

.select_control__indicator-separator {
  display: none !important;
}

.select_control__multi-value {
  background-color: #dbffdf !important;
  border-radius: 4px !important;
}
.select_control__multi-value__label {
  color: #2b8c34 !important;
  font-weight: 500 !important;
}

.select_control__multi-value__remove {
  color: #2b8c34 !important;
}

.select_control__multi-value__remove:hover {
  background-color: #a7eaad !important;
}

/* PIN styles */
.pincode-input-container .pincode-input-text {
  border-radius: 8px;
  margin-right: 10px !important;
  margin-bottom: 5px !important;
  appearance: none;
}

.pincode-input-container .pincode-input-text:last-child {
  margin-right: 0px !important;
}

.tracker {
  border-radius: 12px 12px 0px 0px;
  border: 0.5px solid #ced5cf;
  background: #f7f7f7;
}

.tracker_like {
  border-radius: 0px 0px 0px 8px;
  border-bottom: 0.5px solid #ced5cf;
  border-left: 0.5px solid #ced5cf;
  background: #f7f7f7;
}

.tracker_share {
  border-radius: 0px 0px 8px 0px;
  border-right: 0.5px solid #ced5cf;
  border-bottom: 0.5px solid #ced5cf;
  border-left: 0.5px solid #ced5cf;
  background: #f7f7f7;
}
