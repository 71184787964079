

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    transition: width 0.3s ease-in-out;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    border-radius: 50%;
    outline: none;
    background: #FFC508;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: "";
    text-align: center;
    opacity: .25;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  .slick-dots li.slick-active button {
    width: 10px;
    transition: width 0.3s ease-in-out; 
    background: #2B8C34;
  }

  .slick-dots li.slick-active button:before {
    width: 10px;
    transition: width 0.3s ease-in-out; 
  }

  .slick-dots .slick-active {
    width: 10px;
    transition: width 0.3s ease-in-out;
  }


  .slick-prev, .slick-next{
   z-index: 1;
   width: fit-content;
   height: fit-content;
  }

  .slick-prev{
    left: -10px;
  }

  .slick-next{
    right: -10px
  }

  .slick-prev:before, .slick-next:before {
    content: none;
}

